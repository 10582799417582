<template>
  <section>
    <PricingPromotionModalVue
      v-if="promotionsOpen && locations[activeHotspot]?.promotions?.length > 0"
      :promotions="locations[activeHotspot].promotions"
      @closeModal="togglePromotions"
    />
    <heading-with-background
      heading="Locations and Pricing"
      alignment="right"
    />
    <div id="map-wrapper">
      <map-with-hotspots
        :active="activeHotspot"
        @hotspot-clicked="hotspotClicked"
      />
    </div>
    <div id="pricing-wrapper">
      <h2>{{ locations[activeHotspot].title }}</h2>
      <p>{{ locations[activeHotspot].pricing }}</p>
      <button
        class="dj-cole-coleman-button"
        v-if="locations[activeHotspot]?.promotions?.length > 0"
        @click="togglePromotions"
      >
        Click for promotions
      </button>
    </div>
  </section>
</template>
<script>
import HeadingWithBackground from "../SharedComponentsUI/HeadingWithBackground.vue";
import MapWithHotspots from "../SharedComponentsUI/MapWithHotspots.vue";
import PricingPromotionModalVue from "../SharedComponentsUI/PricingPromotionModal.vue";

export default {
  data() {
    return {
      promotionsOpen: false,
      activeHotspot: "stloutskirts",
      locations: {
        stloutskirts: {
          title: "St. Louis, MO",
          pricing: "$2,400 / 4 hours + $100 / add't hour",
          promotions: [
            "Remaining 2024: $300 off",
            `January - March: $300 off`,
            `July - August: $300 off`,
            "Friday & Sundays: $300 off",
            "Mon - Thurs: $700 off",
          ],
        },
        swmissouri: {
          title: "Southwest Missouri",
          pricing: "$2,600 / 4 hours + $100 / add't hour",
        },
        memphis: {
          title: "Memphis, TN",
          pricing: "$2,800 / 4 hours + $100 / add't hour",
        },
        kansascity: {
          title: "Kansas City, MO",
          pricing: "$2,800 / 4 hours + $100 / add't hour",
        },
        louisville: {
          title: "Louisville, KY",
          pricing: "$2,800 / 4 hours + $100 / add't hour",
        },
        indianapolis: {
          title: "Indianapolis, IN",
          pricing: "$2,800 / 4 hours + $100 / add't hour",
        },
        littlerock: {
          title: "Little Rock, AR",
          pricing: "$2,800 / 4 hours + $100 / add't hour",
        },
        nashville: {
          title: "Nashville, TN",
          pricing: "$2,800 / 4 hours + $100 / add't hour",
        },
        chicago: {
          title: "Chicago, IL",
          pricing: "$2,800 / 4 hours + $100 / add't hour",
        },
        cincinatti: {
          title: "Cincinatti, OH",
          pricing: "$3,300 / 4 hours + $100 / add't hour",
        },
        dallas: {
          title: "Dallas, TX",
          pricing: "$3,300 / 4 hours + $100 / add't hour",
        },
      },
    };
  },
  methods: {
    hotspotClicked(hotspot) {
      this.activeHotspot = hotspot;
    },
    togglePromotions() {
      this.promotionsOpen = !this.promotionsOpen;
    },
  },
  created() {},
  components: {
    HeadingWithBackground,
    MapWithHotspots,
    PricingPromotionModalVue,
  },
  props: [],
};
</script>
<style scoped>
@media screen {
  section {
    min-height: 100vh;
    height: fit-content;
    flex-direction: column;
    position: relative;
  }
  #map-wrapper {
    position: absolute;
    left: -80%;
    height: 50%;
    width: 200%;
  }

  #pricing-wrapper {
    width: calc(100% - 80px);
    height: 40%;
    position: absolute;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    bottom: 10px;
    padding: 40px;
  }
  .dj-cole-coleman-button {
    width: fit-content;
    margin: 20px auto;
    background-color: black;
    outline: 0;
    border-radius: 3px;
    padding: 10px;
    /* font-family: bebas-neue, sans-serif; */
    /* font-weight: 400; */
    /* font-style: normal; */
    color: white;
    /* font-size: 18pt; */
    filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.75));
  }
  h2 {
    /* font-size: 25pt; */
    text-align: center;
  }
  p {
    /* font-size: 18pt; */
    text-align: center;
    margin: 0;
  }

  @media (min-width: 1000px) {
    section {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: row;
    }

    #pricing-wrapper {
      width: 40%;
      height: 100%;
      /* text-align: left; */
      right: 0;
    }
    h2 {
      /* font-size: 40pt; */
    }
    p {
      /* font-size: 20pt; */
    }
  }
}
</style>
