<template>
  <div v-if="isModalOpen" class="backdrop" @click="closeModal"></div>
  <d-j-modal
    v-if="isModalOpen"
    :dj="selectedDj"
    :contact="contact"
    :error="formError"
    @close="closeModal"
    @submit-form="submitForm"
  />
  <section id="cole-friends-page">
    <section id="hero">
      <hero-gallery-vue id="hero-background" />
      <div id="hero-content">
        <HeadingWithBackground heading="Cole's already booked, huh?" />
        <h3>Don't worry. Cole has friends too–just for this reason.</h3>
        <p>
          So, here's the thing: I'm great at my job. That said, I'm not the only
          one. I do have friends in the industry who I love to send work to when
          I can't take it. Since I have to turn away well over a hundred a year,
          it was easier for me to build this program to share my pals!
        </p>
        <p>
          Each DJ on this page is someone I personally love. That said, keep in
          mind:
          <i>I don't get to see these guys live often</i>! If you have an issues
          with this program or any of the DJs on this list, PLEASE let me know
          and I'll remove them prompty.
        </p>
        <p>
          <br />There's only a handful I love and trust, but you can click on
          any of them to bring up a modal that will contain all of their
          information, and can even contact them directly from the contact form
          on the popup.
          <b
            >Even if you contact them directly, tell them I sent you–it'll get
            me one step closer to earning a coffee from them.
          </b>
        </p>
        <p>All built for <i>you</i> by yours truly ❤️</p>
        <p>- Cole</p>
      </div>
    </section>
    <section id="djs">
      <d-j-item
        v-for="dj in randomDjs"
        :key="dj.name"
        :dj="dj"
        @click="openModal(dj)"
      />
    </section>
  </section>
</template>

<script>
import HeadingWithBackground from "../components/SharedComponentsUI/HeadingWithBackground.vue";
import DJModal from "../components/VendorComponents/DJModal.vue";
import DJItem from "../components/VendorComponents/DJItem.vue";
import { djs } from "../data/djs.json";
import axios from "axios";
import HeroGalleryVue from "../components/SharedComponentsUI/HeroGallery.vue";

export default {
  data() {
    return {
      contact: {
        name: "",
        phone: "",
        email: "",
      },
      djs,
      selectedDj: undefined,
      isModalOpen: false,
      formError: "",
    };
  },
  components: {
    HeadingWithBackground,
    DJModal,
    DJItem,
    HeroGalleryVue,
  },
  computed: {
    randomDjs() {
      let array = [...this.djs]; // Avoid mutating the original array
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
  },
  methods: {
    openModal(dj) {
      this.selectedDj = dj;
      this.isModalOpen = true;
    },

    closeModal() {
      this.isModalOpen = false;
    },
    async submitForm(form) {
      this.contact.name = form.name;
      this.contact.email = form.email;
      try {
        const response = await axios.put(
          `https://yjt6qp6bk0.execute-api.us-east-1.amazonaws.com/dj-form`,
          {
            contact: form,
            dj: {
              name: this.selectedDj.name,
              email: this.selectedDj.email,
            },
          }
        );
        if (response.status === 200) {
          this.closeModal();
        } else {
          this.formError = "An error occurred while submitting the form.";
        }
      } catch (error) {
        this.formError = "An error occurred while submitting the form.";
      }
    },
    getRandomPhoto(dj) {
      if (dj.gallery && dj.gallery.length > 0) {
        const randomIndex = Math.floor(Math.random() * dj.gallery.length);
        console.log(randomIndex);
        return dj.gallery[randomIndex];
      }
      return "dur"; // Return a default or placeholder image if needed
    },
  },
};
</script>

<style>
#cole-friends-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#hero {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  z-index: 1;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#hero-content {
  z-index: 1;
  padding: 20%;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.75);
}

#hero-background {
  max-height: 100vh;
  width: 100vw;
}

#djs {
  width: 80%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 50px;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* White backdrop with 50% opacity */
  z-index: 8;
}
</style>
