<template>
  <div id="dj-modal">
    <button class="close-button" @click="$emit('close')">×</button>
    <div class="dj-modal-heading">
      <p id="dj-promotion" v-if="dj.promotion">
        {{ dj.promotion }}
      </p>
      <img
        :src="
          'https://colewebsitefiles.s3.us-east-1.amazonaws.com/djs/' +
          dj.photos[0]
        "
        alt=""
      />
      <h1>
        {{ dj.name }}
      </h1>
      <button class="contact-button" @click="toggleContactForm">
        Contact {{ dj.name }}
      </button>
    </div>
    <div class="dj-photo-gallery" v-if="dj.photos">
      <img
        v-for="image in dj.photos"
        :key="image"
        :src="
          `https://colewebsitefiles.s3.us-east-1.amazonaws.com/djs/` + image
        "
        alt=""
        class="dj-photo"
      />
    </div>
    <div v-if="showBio" class="dj-bio">
      <h3>About {{ dj.name }}</h3>
      <p id="dj-bio" v-html="dj.description"></p>
      <h3>Cole's Notes</h3>
      <p id="dj-coles-notes" v-html="dj.coles_notes"></p>
      <h3>Contact</h3>
      <div class="contact-info" v-if="dj.phone">
        <p class="contact-info-type">Phone</p>
        <a :href="`tel:${formattedPhone}`">{{ formattedPhone }}</a>
      </div>
      <div class="contact-info" v-if="dj.email">
        <p class="contact-info-type">Email</p>
        <a :href="`mailto:${dj.email}`">{{ dj.email }}</a>
      </div>
      <div class="contact-info" v-if="dj.website">
        <p class="contact-info-type">Website</p>
        <a href="dj.website" target="_blank">{{ dj.website }}</a>
      </div>
      <p class="or-button-text">
        ~or click the button below to use Cole's Contact Form:~
      </p>
      <button class="contact-button" @click="toggleContactForm">
        Contact {{ dj.name }}
      </button>
    </div>
    <div v-else>
      <form @submit.prevent="validateAndSubmitForm">
        <label for="name">Name:</label>
        <input
          name="name"
          type="text"
          v-model="contactForm.name"
          placeholder="Your Name"
          required
        />
        <label for="phone">Phone Number:</label>
        <input
          name="phone"
          type="tel"
          v-model="contactForm.phone"
          placeholder="Your number"
          required
        />
        <label for="email">Email:</label>
        <input
          name="email"
          type="email"
          v-model="contactForm.email"
          placeholder="Your Email"
          required
        />
        <label for="message">Message:</label>
        <textarea
          name="message"
          v-model="contactForm.message"
          placeholder="Your Message"
          required
        ></textarea>
        <div v-if="error" class="error-message">{{ error }}</div>
        <div class="button-wrapper">
          <button type="submit" class="send-button" :disabled="isSubmitting">
            <span v-if="isSubmitting" class="spinner"></span>
            <span v-else>Send</span>
          </button>
          <button type="button" class="back-button" @click="toggleContactForm">
            Back to Bio
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dj", "contact", "error"],
  data() {
    return {
      activePhotoIndex: 0,
      showBio: true,
      contactForm: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      formError: "", // To store error messages
      isSubmitting: false, // To track submission state
    };
  },
  computed: {
    formattedPhone() {
      const phone = this.dj.phone;
      if (!phone) return "";
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    },
  },
  methods: {
    toggleContactForm() {
      this.showBio = !this.showBio;
    },
    validateAndSubmitForm() {
      if (
        !this.contactForm.name ||
        !this.contactForm.email ||
        !this.contactForm.message
      ) {
        this.formError = "All fields are required.";
        return;
      }
      if (!this.validateEmail(this.contactForm.email)) {
        this.formError = "Please enter a valid email address.";
        return;
      }
      this.formError = "";
      this.sendContactForm();
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    sendContactForm() {
      this.isSubmitting = true;
      // Handle form submission logic here
      this.$emit("submit-form", this.contactForm);
      //   this.isSubmitting = false;
      //   this.toggleContactForm(); // Optionally switch back to bio after sending
    },
  },
  created() {
    this.contactForm.name = this.contact.name;
    this.contactForm.phone = this.contact.phone;
    this.contactForm.email = this.contact.email;
  },
};
</script>

<style scoped>
#dj-modal {
  z-index: 10;
  position: absolute;
  background-color: white;
  width: 80vw;
  max-width: 700px;
  max-height: 80vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.75));
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 20px;
  padding: 40px;
  box-sizing: border-box;
  text-align: left;
}

.dj-modal-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.dj-modal-heading > h1 {
  font-family: calluna;
  text-transform: uppercase;
  font-weight: 600;
}

.dj-modal-heading > img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.dj-photo-gallery {
  display: flex;
  align-items: center;
  overflow: scroll;
  position: relative;
  margin-top: 20px;
}
.dj-photo {
  width: 200px;
  height: 200px;
  object-fit: cover;
  aspect-ratio: 1/1;
  margin: 0px 10px;
  border-radius: 8px;
}

.dj-bio {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}
#dj-promotion {
  background-color: rgb(180, 0, 0);
  position: absolute;
  right: 0;
  top: 20px;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.75));
  /* font-size: 1.5rem; */
  color: white;
  width: fit-content;
  font-family: bebas neue;
  padding: 10px;
  margin: 20px auto;
}

.contact-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  border-top: 1px solid darkgray;
}
@media (max-width: 600px) {
  .contact-info {
    flex-direction: column;
    align-items: flex-start;
  }
}

.or-button-text {
  width: 100%;
  text-align: center;
  font-weight: 600;
}

button {
  background-color: black;
  color: white;
  border: none;
  /* font-size: 1.6rem; */
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  font-family: bebas neue;
  border-radius: 3px;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5));
}

button:hover {
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.75));
}

form {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
label {
  width: 90%;
}
input,
textarea {
  width: 80%;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

textarea {
  height: 100px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  /* font-size: 2rem; */
  cursor: pointer;
  z-index: 11;
}

.error-message {
  color: red;
  font-style: italic;
  margin: 10px 0;
}

.contact-form {
  margin-bottom: 30px;
}

.spinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 1s linear infinite;
  display: inline-block;
  margin-right: 5px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
