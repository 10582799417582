<template>
  <section>
    <h1>A couple of topics that may come up:</h1>
    <div class="topics-container">
      <ul class="topics-navigation">
        <li
          v-for="(topic, index) in topics"
          :key="index"
          @click="chooseTopic(index)"
          :class="chosenIndex === index ? 'chosen' : ''"
        >
          {{ topic.title }}
        </li>
      </ul>

      <div class="topics-body">
        <p v-html="topics[chosenIndex].body"></p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      chosenIndex: 0,
      topics: [
        {
          title: "Pre-Ceremony",
          body: "Before the ceremony, I feel that it is important to set the mood. That’s why, before every wedding ceremony, I’m sure to have background music playing to match the mood. My favorite playlist to include contains piano and string covers of popular and current songs. If you’re listening intently to the music, it’s easy to pickup on. Otherwise, it’s simply nice, romantic music. I love when guests come up to me and ask “Is that Kings of Leon”?<br/><br/>In a sense, yes.",
        },
        {
          title: "Dinner Moods",
          body: "This is, quite possibly, one of the most integral parts of the night. Take any medium or variety of art: you must first prime the subject before you could ever hope to have a shot at creating something truly masterful. You can apply the same principles to an unforgettable night; while a painter has to prime their canvas, I have to prime my audience. To do this, I carefully curate a dinner playlist on the fly, never playing from a rigid playlist, always matching the demographics and mood of the crowd at that specific moment. <br/><br/>In the beginning of the night, we’ll start off easy and soft, so that everyone is on the same page. As we progress through this section of the evening, we’ll slowly bring the energy up, going through singer-songwriter and acoustic hits, to classic sing-alongs, familiar hits, and forgotten (though easily remembered) throwbacks, then finally to non-danceable newer hits, songs with stronger baselines. This is absolutely important, to make sure that no-one is left-behind, and everyone is primed and ready to party! ",
        },
        {
          title: "Favorite Genres",
          body: "Though I’m comfortable with all genres, there are certain genres that hold a special place in my heart. As a baby of the 90s, child of the 2000s, and teenager / young adult of the 2010s there’s been a plethora of influences in my particular palate, including classic rock and country, 90s rap and R&B, 2000s and 2010s hits, and alternative rock. I truly believe that my diverse tastes have gone a long way towards shaping my craft by allowing me to go deeper than the surface of a genre, pulling out deep cuts that only a true fan of a particular genre would know. I love pulling a track out of deep left field and throwing it into the mix. By surprising my crowd and throwing in something unexpected that fits, I’m able to keep everyone on their toes and guessing, adding another dimension to the experience.",
        },
        {
          title: "Equipment",
          body: "I believe that a DJ’s equipment should be more than just functional. Speakers should do more than make sound, and that a 8’ table and cloth just won’t cut it. I’ve spent years and tens of thousands of dollars in pursuit of the perfect setup, and will always invest more time and money in improvement. In my opinion, my equipment should complement each other and enhance the space around it, without being so flashy and outlandish as to distract from anything else. From DJ furniture that hides wires and keeps them neat, to speakers that don’t require gaudy tripods, even to mixers and controllers that turn like an old-school turntable, I’m proud to provide equipment that inspires the mind. <br/><br/>It’s not all looks though. I’ve assembled equipment that is modular—each piece is independent and functional without the other. This creates true flexibility in setup and location. Whether this means wireless placement of a speaker into another space, or unbelievably quick movement from one space to another, the modularity gives me freedom to quickly and easily have music and crowd control wherever necessary. As an added bonus for me? It allows me to setup and teardown in 15-20 minutes.",
        },
        {
          title: "Process",
          body: "I’m likely to be the easiest part of your wedding day. Here’s my process, from reserving me, all the way to the big day: <br/> <br/>To reserve me for a day, get ahold of me, ask any questions, and, when you’re ready, I can gather some basic information about you and your event and set you up on our online portal. The portal is where you can sign the contract and pay the retainer ($200). Then, the portal becomes your hub for your event, and how we’ll communicate details. You’ll have access to forms for applicable parts of your event, such as the ceremony, reception, requests, do not play, and music preferences. The forms will walk you through any possible selection, so that you can be at ease knowing that I have everything I need. If you have any questions during this time, you’ll have my cell number for texts or calls at anytime. In the week preceding the event, we can schedule a phone call to chat about your event, hammer down logistics, and get everything ironed out. Finally, regarding payments, you can make payments at anytime on the portal, and the remaining balance is due two weeks before the event date. <br/><br/>No mandatory meetings, no payment schedules, no deadlines. Easy, huh? ",
        },
        {
          title: "Requests",
          body: `Do I take requests? The short answer is: "yes, of course". The long answer is a bit more convoluted, but here goes: <br/><br/>I do take requests, but never promise to play them all. When you reserve your wedding with me, you will be given access to a portal. The portal houses a handful of forms, including a form for song requests. Here is where you’ll put all of the songs you’d like to hear! I’ll do everything I can to place them in wherever they might fit, but my top priority is keeping the energy where it needs to be—sometimes some songs just don’t fit the flow of the night, as great as the song may be. Whether a request is made on the portal or live by a guest, I ask for the professional courtesy of freedom over the night, though I'll certainly ensure it has your signature all over it.. `,
        },
        {
          title: "Emergencies",
          body: "What happens if I’m unable to perform at an event in which I’m hired? Though I’ve never missed an event, I’ve performed through some severe circumstances, and have taken extreme safeguards and precautions to give me the maximum chance at performance in these circumstances, but things could always happen. Here are the safeguards I’ve put in place in case of emergency: <br/><br/>Unless I, or an immediate family member are severely hospitalized or deceased, I’ll be there. In the event of severe weather, traffic, or vehicular issues, I am always sure to aim for arrival with a minimum of 1.5 hours prior to start time, adding a half-hour for every hour of travel. This ensures I am there in time to setup, troubleshoot, and have music on before guest arrival. In the rare event in which I’m unable to attend, you’ll be alerted as soon as possible, and I will have a replacement in place. In this case, I have a network of DJs who I’ve trained personally for my previous team. One of these DJs will be reserved with a contract and a deposit as soon as I’m aware of the possibility of my absence. They’ll perform just as I would have, and will give you as similar of an experience as you would have had with me. <br/><br/>Again, this has never happened, but we’ve planned it out as meticulously as possible, should it. ",
        },
        {
          title: "Coordination",
          body: "One of the unique advantages of having me as your DJ is my vast experience in different parts of the events industry. I’ve worked and shadowed in venues, catering, bartending, photography, videography, planning, and more. This history allows me to intimately know the needs of these vendor categories and to meet them with precision. Having me as your DJ ensures that, on top of my responsibilities in the host, MC, and DJ roles, I’m able to pull all of the pieces together, run and adjust the timeline, and ensure that all of your vendors have their needs met.  Even more, I’m able to act as a utility player, and fill any gaps that may arise. During a room flip between ceremony and reception, you’ll often find me helping venue staff setup tables and chairs, ensuring a quicker transition. It isn’t uncommon to find me helping a short-staffed caterer, helping to bar-back a backed up bar line, or even supporting a photographer or videographer who may need some auxiliary support with lighting. <br/><br/>In my opinion, my job isn’t a monolithic list of responsibilities. Though I have a bare-minimum set with music, MC, and hosting, I pride myself on my ability to be a team player, as we’re all working towards the same goal: your perfect event. ",
        },
      ],
    };
  },
  methods: {
    chooseTopic(index) {
      this.chosenIndex = index;
    },
  },
  created() {},
  components: {},
  props: [],
};
</script>

<style scoped>
@media screen {
  section {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    /* font-size: 18pt; */
  }

  .topics-container {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .topics-navigation {
    width: 100%;
    padding: 0;
  }

  li {
    font-family: bebas-neue, sans-serif;
    list-style: none;
    text-align: right;
    /* font-size: 15pt; */
    line-height: 25pt;
    padding-right: 15px;
    cursor: pointer;
  }
  li:hover {
    filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.25));
  }

  .chosen {
    background-color: black;
    color: white;
    /* padding-right: 0; */
  }

  .topics-body {
    width: calc(100% - 60px);
    margin: 30px;
    height: 50%;
    overflow: scroll;
  }
  @media (min-width: 600px) {
    h1 {
      /* font-size: 30pt; */
    }

    .topics-container {
      width: 80%;
      height: unset;
    }

    .topics-navigation {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      border-right: 3px solid black;
      min-width: 175px;
      width: 20%;
      min-height: 100%;
    }

    li {
      /* font-size: 20pt; */
      line-height: 35pt;
    }

    .topics-body {
      width: 70%;
      height: unset;
      overflow: unset;
      margin: unset;
      padding: 10px;
    }
  }
  @media (min-width: 1000px) {
    .topics-container {
      width: 60%;
      flex-direction: row;
    }
  }
}
</style>
