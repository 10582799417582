<template>
  <div v-if="isModalOpen" class="backdrop" @click="closeModal"></div>
  <vendor-modal
    v-if="isModalOpen"
    :vendor="selectedVendor"
    :contact="contact"
    :error="formError"
    @close="closeModal"
    @submit-form="submitForm"
  />
  <section id="cole-friends-page">
    <section id="hero">
      <hero-gallery-vue id="hero-background" />
      <div id="hero-content">
        <HeadingWithBackground heading="Cole has friends too" />
        <h3>And they're pretty damn talented.</h3>
        <p>
          As a wedding professional who relies entirely on referrals for my
          business, it's only fair for me to also share my best-kept vendors
          with my clients. Also, my clients certainly deserve the best
          recommendations and vendors.
        </p>
        <p>
          Every vendor on this page is someone I've worked with in the past and
          loved working with! Keep in mind:
          <i>past performance does not guarantee future results</i>! If you have
          an issues with this program or any of the vendors on this list, PLEASE
          let me know.
        </p>
        <p>
          I've asked every vendor on this page to provide a description and
          message to my clients, and have offered the option to provide a
          promotion directly to my clients!
          <b> (Vendors who do will have a ribbon on their photo)</b
          ><br /><br />You can click on any of the vendors in the categories
          below to bring up a modal that will contain all of their information,
          and can even contact them directly from the contact form on the popup.
        </p>
        <p>All built for <i>you</i> by yours truly ❤️</p>
        <p>- Cole</p>
      </div>
    </section>
    <section id="vendors">
      <vendor-category
        v-for="(vendorsList, index) in vendors"
        @select-vendor="openModal"
        :key="index"
        class="vendor-category"
        :category="vendorsList"
      />
    </section>
  </section>
</template>

<script>
import HeadingWithBackground from "../components/SharedComponentsUI/HeadingWithBackground.vue";
import VendorModal from "../components/VendorComponents/VendorModal.vue";
import VendorCategory from "../components/VendorComponents/VendorCategory.vue";
import vendors from "../data/vendors.json";
import axios from "axios";
import HeroGalleryVue from "../components/SharedComponentsUI/HeroGallery.vue";

export default {
  data() {
    return {
      contact: {
        name: "",
        phone: "",
        email: "",
      },
      vendors,
      selectedVendor: undefined,
      isModalOpen: false,
      formError: "",
    };
  },
  components: {
    HeadingWithBackground,
    VendorModal,
    VendorCategory,
    HeroGalleryVue,
  },
  methods: {
    openModal(vendor) {
      console.log(vendor);
      this.selectedVendor = vendor;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    async submitForm(form) {
      this.contact.name = form.name;
      this.contact.email = form.email;
      try {
        const response = await axios.put(
          `https://yjt6qp6bk0.execute-api.us-east-1.amazonaws.com/vendor-form`,
          {
            contact: form,
            vendor: {
              name: this.selectedVendor.name,
              email: this.selectedVendor.email,
            },
          }
        );
        if (response.status === 200) {
          this.closeModal();
        } else {
          this.formError = "An error occurred while submitting the form.";
        }
      } catch (error) {
        this.formError = "An error occurred while submitting the form.";
      }
    },
    getRandomPhoto(vendor) {
      if (vendor.gallery && vendor.gallery.length > 0) {
        const randomIndex = Math.floor(Math.random() * vendor.gallery.length);
        console.log(randomIndex);
        return vendor.gallery[randomIndex];
      }
      return "dur"; // Return a default or placeholder image if needed
    },
  },
};
</script>

<style>
#cole-friends-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#hero {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  z-index: 1;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#hero-content {
  z-index: 1;
  padding: 20%;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.75);
}

#hero-background {
  max-height: 100vh;
  width: 100vw;
}

#vendors {
  width: 80%;
  margin-bottom: 50px;
}
.category-pool {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* White backdrop with 50% opacity */
  z-index: 8;
}
</style>
