<template>
  <div class="dj">
    <div class="dj-image" :style="{ backgroundImage: `url(${primaryPhoto})` }">
      <div class="promotion" v-if="dj.promotion"></div>
    </div>
    <h5 class="dj-name">{{ dj.name }}</h5>
  </div>
</template>

<script>
export default {
  computed: {
    primaryPhoto() {
      if (this.dj.photos.length === 0) return 0;

      return (
        "https://colewebsitefiles.s3.us-east-1.amazonaws.com/vendors/" +
        this.dj.photos[Math.floor(Math.random() * this.dj.photos.length)]
      );
    },
  },
  props: {
    dj: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.dj {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 200px;
  width: 300px;
  scroll-snap-align: center;
  padding: 0 3px;
}
.dj:hover > .dj-image {
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.5));
}
.promotion {
  background-color: rgb(180, 0, 0);
  color: white;
  position: absolute;
  height: 0.5rem;
  margin: 0;
  top: 0;
  right: 10px;
  clip-path: polygon(0 0, 100% 0, 100% 75%, 50% 100%, 0 75%);
}

.dj-image {
  width: 100%;
  position: relative;
  background: #f9f9f9;
  border-radius: 4px;
  overflow: hidden;
  aspect-ratio: 2 / 1;
  background-size: cover;
  background-position: center;
  max-width: 100%;
}

.dj-name {
  width: 100%;
  background: none;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  margin-top: 0.5rem;
  text-align: left;
  font-family: calluna;
  cursor: pointer;
}
</style>
