<template>
  <div class="vendor-category">
    <h1 class="category-name">{{ category.name }}</h1>
    <!-- Scroll Indicators -->

    <!-- Vendor List -->
    <div class="category-scroll-container">
      <div class="scroll-indicator left" v-if="showLeftIndicator"></div>
      <div class="scroll-indicator right" v-if="showRightIndicator"></div>
      <div class="category-list" :id="id" @scroll="updateScrollPosition">
        <vendor-category-item
          v-for="(vendor, index) in randomVendors"
          :key="index"
          :vendor="vendor"
          @click="selectVendor(vendor)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VendorCategoryItem from "./VendorCategoryItem.vue";
export default {
  data() {
    return {
      scrollPosition: 0,
      id: undefined,
      scrollContainer: null,
    };
  },
  methods: {
    selectVendor(vendor) {
      this.$emit("select-vendor", vendor);
    },
    updateScrollPosition() {
      this.scrollPosition = this.scrollContainer?.scrollLeft;
    },
  },
  computed: {
    randomVendors() {
      let array = [...this.category.vendors]; // Avoid mutating the original array
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
    showLeftIndicator() {
      if (!this.scrollContainer) return false;
      return this.scrollPosition > 0;
    },
    showRightIndicator() {
      if (!this.scrollContainer) return false;

      const tolerance = 11; // Allow a small margin for precision issues
      return (
        this.scrollContainer.scrollLeft + this.scrollContainer.clientWidth <
        this.scrollContainer.scrollWidth - tolerance
      );
    },
  },
  mounted() {
    this.id = "scroll-container-" + this.category.name;
    setTimeout(() => {
      this.scrollContainer = document.getElementById(this.id);
      this.updateScrollPosition();
    }, 2000);
  },
  components: { VendorCategoryItem },
  props: {
    category: {
      type: Object,
    },
  },
  emits: ["select-vendor"],
};
</script>

<style>
.scroll-indicator {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 100%;
  pointer-events: none; /* Allow clicks to pass through */
  z-index: 2;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  transition: opacity 0.3s ease;
}

.scroll-indicator.left {
  left: 0;
}

.scroll-indicator.right {
  transform: scaleX(-1);
  right: 0;
}

.category-scroll-container {
  position: relative;
}

.category-name {
  text-align: left;
}

.category-list {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  align-items: start;
  overflow-x: auto;
  scroll-behavior: smooth;
  position: relative; /* For scroll indicators */
}

.category-list::-webkit-scrollbar {
  display: none; /* Hide scrollbar for better aesthetics */
}
</style>
